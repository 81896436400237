import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import {
    PartitionPrefI,
    S25OrgPartitionPrefsService,
} from "../../organization-details/s25.org.partition.prefs.service";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-bulk-edit-org-partition-prefs")
@Component({
    selector: "s25-ng-bulk-edit-org-partition-prefs",
    template: `<div *ngIf="this.init">
        <span class="c-margin-top--single c-margin-bottom--half c-margin-right--half ngInlineBlock ngBold"
            >Partition Preferences</span
        >
        <s25-ng-org-partition-prefs [readOnly]="false" [(modelBean)]="this.partitions"></s25-ng-org-partition-prefs>
        <s25-ng-bulk-edit-save [submitFn]="saveFn" [buttonText]="this.saveButtonText"></s25-ng-bulk-edit-save>
    </div>`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditOrgPartitionPrefsComponent implements OnInit {
    @Input() itemIds: number[];
    partitions: PartitionPrefI;
    init = false;
    saveButtonText = "Save Partition Preferences";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit(): void {
        this.partitions = { groups: [] };
        this.init = true;
        this.cd.detectChanges();
    }

    get saveFn(): any {
        return this.save.bind(this);
    }

    save() {
        return S25OrgPartitionPrefsService.savePrefs(this.partitions, this.itemIds);
    }
}
