//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { S25BulkEditUtil, S25BulkMap } from "../s25.bulk.edit.util";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { jSith } from "../../../util/jquery-replacement";
import { PayloadI } from "../../../pojo/EventTypeBulkEditPayloadI";
import { MultiselectModelI } from "../../s25-multiselect/s25.multiselect.component";
import { S25ItemI } from "../../../pojo/S25ItemI";

@TypeManagerDecorator("s25-ng-bulk-edit-multiselect")
@Component({
    selector: "s25-ng-bulk-edit-multiselect",
    template: `
        <s25-loading-inline model="{}"></s25-loading-inline>
        <div *ngIf="this.init">
            <span *ngIf="this.itemType != 'layout'">
                <span class="c-margin-top--single c-margin-right--half ngInlineBlock ngBold"
                    >Add/Remove {{ this.title }}</span
                >
                <s25-toggle-button
                    (modelValueChange)="this.onToggleChange($event)"
                    [modelValue]="this.remove"
                    [trueLabel]="trueLabel"
                    [falseLabel]="falseLabel"
                    class="ngBlock c-margin-bottom--single"
                ></s25-toggle-button>
            </span>
            <div *ngIf="this.itemType === 'layout'" class="c-margin-top--half c-margin-bottom--half ngBold">
                Remove {{ this.title }}
            </div>
            <s25-ng-multiselect-search-criteria
                [selectedItems]="this.selectedItems"
                [(modelBean)]="this.modelBean"
                honorMatching="true"
                [type]="this.type"
                [useSecurity]="true"
                [customFilterValue]="customFilterValue"
            ></s25-ng-multiselect-search-criteria>
            <s25-ng-bulk-edit-save
                [submitFn]="updateFn"
                [buttonText]="this.saveButtonText"
                [buttonDisabled]="this.buttonDisabled"
            ></s25-ng-bulk-edit-save>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditMultiselectComponent implements OnInit {
    @Input() itemTypeId: any = undefined;
    @Input() itemType?: any = undefined;
    @Input() itemIds: any = undefined;
    @Input() fields?: string[] = ["quantity", "comments"]; //Used to hide fields
    @Input() idsOnly: boolean = false; // Event cat pass ids instead of the object, so make this flexible either just ids or the object

    modelBean: MultiselectModelI = { showResult: true, showMatching: false, omitReadOnly: true };
    init: boolean = false;
    type: any;
    remove: boolean = false;
    @Input() falseLabel: string = "Add";
    @Input() trueLabel: string = "Remove";
    mappingModelBean: any;
    title: any;
    saveButtonText: string;
    customFilterValue: string = "";

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        this.mappingModelBean = S25BulkMap[this.itemTypeId][this.itemType];
        this.type = this.mappingModelBean.searchCriteriaType;
        this.modelBean.hasQuantity = this.mappingModelBean.hasQuantity;
        this.title = this.mappingModelBean.title;

        S25LoadingApi.init(this.elementRef.nativeElement);
        if (this.itemType === "layout") {
            this.remove = true;
            this.saveButtonText = "Remove " + this.mappingModelBean.title;
            this.customFilterValue = "&include=s25";
        } else {
            this.saveButtonText = "Add " + this.mappingModelBean.title;
        }

        if (
            this.itemTypeId === 7 &&
            (this.itemType === "eventRequirements" || this.itemType === "eventOtherRequirements")
        ) {
            this.itemType === "eventRequirements"
                ? (this.customFilterValue = "&requirement_type=7")
                : (this.customFilterValue = "&requirement_type=6");
        }

        this.init = true;
        this.cd.detectChanges();

        if (this.type === "organizations") {
            S25LoadingApi.destroy(this.elementRef.nativeElement);
            this.cd.detectChanges();
        } else {
            // call search criteria data
            this.modelBean.dataPromise &&
                this.modelBean.dataPromise.then(() => {
                    S25BulkEditUtil.done(this.elementRef.nativeElement);
                    this.cd.detectChanges();
                });
        }
    }

    onToggleChange(event: any) {
        this.remove = event;
        let removeButtonText = "Remove " + this.mappingModelBean.title;
        let addButtonText = "Add " + this.mappingModelBean.title;
        this.saveButtonText = this.remove ? removeButtonText : addButtonText;
        if (this.title === "Features") {
            // hide quantity selector in popup if removing features; show if adding features
            if (this.remove) {
                this.modelBean.hasQuantity = false;
            } else {
                this.modelBean.hasQuantity = this.mappingModelBean.hasQuantity;
            }
        }
        this.cd.detectChanges();
    }

    get updateFn() {
        return this.update.bind(this);
    }

    update() {
        let addItemIds: any = [];
        let removeItemIds: any = [];
        this.remove ? (removeItemIds = this.modelBean.addedItems) : (addItemIds = this.modelBean.addedItems);
        if (this.idsOnly) {
            addItemIds = S25Util.toItemIds(addItemIds);
            removeItemIds = S25Util.toItemIds(removeItemIds);
        }
        // console.log({addItemIds: addItemIds, removeItemId:removeItemIds  });
        if (this.remove && this.itemType === "layout" && removeItemIds.length === 0) {
            alert("Please enter a layout.");
        } else if (this.itemType != "layout" && addItemIds.length === 0 && removeItemIds.length === 0) {
            if (this.itemTypeId === 7) {
                alert("Please enter a " + this.mappingModelBean.singular.toLowerCase() + ".");
            } else {
                alert("Please enter a " + this.itemType + ".");
            }
        } else {
            if (this.itemTypeId === 7) {
                // itemTypeId 7 = event type
                let selectedItems: S25ItemI[] = [];
                let payload: PayloadI = undefined;
                if (this.remove) {
                    if (this.itemType === "eventRoles") {
                        jSith.forEach(removeItemIds, (key, i) => {
                            if (i !== -1 && i !== -2) {
                                selectedItems.push({ [this.mappingModelBean.nodeIdVal]: i });
                            }
                        });
                    } else {
                        jSith.forEach(removeItemIds, (key, i) => {
                            selectedItems.push({ [this.mappingModelBean.nodeIdVal]: i });
                        });
                    }
                    payload = {
                        idList: this.itemIds,
                        remove: {
                            [this.mappingModelBean.nodeNameVal]: selectedItems,
                        },
                    };
                } else {
                    jSith.forEach(addItemIds, (key, i) => {
                        selectedItems.push({ [this.mappingModelBean.nodeIdVal]: i });
                    });
                    payload = {
                        idList: this.itemIds,
                        [this.mappingModelBean.nodeNameVal]: selectedItems,
                    };
                }
                return this.mappingModelBean.service(payload);
            } else {
                return this.mappingModelBean.service(this.itemIds, addItemIds, removeItemIds);
            }
        }
    } //END update
}
