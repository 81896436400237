//@author: travis

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25ItemI } from "../../../pojo/S25ItemI";
import { OrganizationService } from "../../../services/organization.service";
import { jSith } from "../../../util/jquery-replacement";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25BulkMap } from "../s25.bulk.edit.util";
import { S25BulkEditSaveApi } from "../save/s25.bulk.edit.save.api";

@TypeManagerDecorator("s25-ng-bulk-edit-org-contacts")
@Component({
    selector: "s25-ng-bulk-edit-org-contacts",
    template: `<div *ngIf="this.init">
        <span class="c-margin-top--single c-margin-bottom--half c-margin-right--half ngInlineBlock ngBold"
            >Associated Contacts</span
        >
        <s25-toggle-button
            (modelValueChange)="this.onToggle($event)"
            [modelValue]="this.remove"
            [trueLabel]="'Remove'"
            [falseLabel]="'Add'"
        ></s25-toggle-button>
        <s25-ng-contacts-editable
            [(modelBean)]="this.orgContacts"
            [itemTypeId]="itemTypeId"
            [r25UserOnly]="false"
            [primaryContact]="!this.remove"
            (modelBeanChange)="onModelBeanChange($event)"
        ></s25-ng-contacts-editable>
        <s25-ng-bulk-edit-save
            [submitFn]="updateFn"
            [buttonText]="this.saveButtonText"
            [submitFn]="update"
        ></s25-ng-bulk-edit-save>
    </div>`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditOrgContactsComponent implements OnInit {
    @Input() itemIds: number[];
    @Input() itemTypeId?: number = 3;
    init: boolean = false;
    orgContacts: S25ItemI[];
    remove: boolean = false;
    saveButtonText = "Add Associated Contacts";
    mappingModelBean: any;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit(): void {
        this.mappingModelBean = S25BulkMap[this.itemTypeId].contact;
        this.orgContacts = [];
        this.init = true;
        this.cd.detectChanges();
    }

    get updateFn() {
        return this.update.bind(this);
    }

    update() {
        if (this.orgContacts && this.orgContacts.length < 1) {
            return jSith.reject("Please select a contact");
        }
        let primaryContact = !this.remove && S25Util.array.getByProp(this.orgContacts, "isPrimary", true);
        let orgsArr = this.itemIds.map((id) => {
            return {
                organization_id: id,
                primary_cont_id: primaryContact ? primaryContact.itemId : "",
            };
        });

        let contactsArr = this.orgContacts.map((c) => {
            return {
                cont_id: c.itemId,
                role_id: (c.property && c.property.itemId) || "",
            };
        });
        return OrganizationService.updateAssociatedContacts(orgsArr, contactsArr, this.remove);
    }

    onToggle(event: any) {
        this.remove = event;

        let removeButtonText = "Remove " + this.mappingModelBean.title;
        let addButtonText = "Add " + this.mappingModelBean.title;

        this.saveButtonText = this.remove ? removeButtonText : addButtonText;
        this.cd.detectChanges();
    }

    onModelBeanChange() {
        S25BulkEditSaveApi.reset(this.elementRef.nativeElement);
    }
}
