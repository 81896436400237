//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { S25BulkEditUtil, S25BulkMap } from "../s25.bulk.edit.util";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-bulk-edit-task-action")
@Component({
    selector: "s25-ng-bulk-edit-task-action",
    template: `
        <s25-loading-inline model="{}"></s25-loading-inline>
        <div *ngIf="this.init" class="c-margin-top--half">
            <!--
          <div class="ngTaskActionButtons c-modal-actions-flex" >
               <div  class="taskStateChoice taskStatePositive ngCpointer ngNoSelect ngInlineBlock" (click)="assign()" tabindex="0">
                    <div class="sprite_approve ngInlineBlock">
                         <svg class="c-svgIcon" role="img">
                              <title>Thumbs Up</title>
                              <use xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#thumbs--thumbs-up"  ></use>
                         </svg>
                    </div>
                    <div class="ngInlineBlock">
                         Assign
                    </div>
               </div>
               <div class="taskStateChoice taskStateNegative ngCpointer ngNoSelect ngInlineBlock" (click)="deny()"  tabindex="0">
                    <div class="sprite_deny ngInlineBlock">
                         <svg class="c-svgIcon" role="img">
                              <title>Thumbs Down</title>
                              <use xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#thumbs--thumbs-down"></use>
                         </svg>
                    </div>
                    <div class="ngInlineBlock">
                         Dendy
                    </div>
               </div>
          </div>
          -->
            <span class="c-margin-top--single c-margin-bottom--half c-margin-right--half ngInlineBlock ngBold"
                >Actions:</span
            >
            <s25-toggle-button
                (modelValueChange)="this.onToggleChange($event)"
                [modelValue]="this.action"
                [trueLabel]="trueLabel"
                [falseLabel]="falseLabel"
            ></s25-toggle-button>
            <s25-ng-bulk-edit-save
                [submitFn]="updateFn"
                [buttonText]="this.saveButtonText"
                [buttonDisabled]="this.buttonDisabled"
            ></s25-ng-bulk-edit-save>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditTaskActionComponent implements OnInit {
    @Input() itemTypeId: any = undefined;
    @Input() itemType?: any = undefined;
    @Input() itemIds: any = undefined;
    init: boolean = false;
    @Input() falseLabel: string = "Approve";
    @Input() trueLabel: string = "Deny";
    mappingModelBean: any;

    action: boolean; // true = deny, false = assign
    todoItemIds: any = [];

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    async ngOnInit() {
        S25LoadingApi.init(this.elementRef.nativeElement);
        // console.log({item: this.itemIds})
        //todo
        this.todoItemIds = S25Util.deepCopy(this.itemIds);
        this.todoItemIds = this.todoItemIds.filter(function (x: any) {
            return x.indexOf("todo") !== -1;
        });
        this.cd.detectChanges();
        this.mappingModelBean = S25BulkMap[this.itemTypeId][this.itemType];
        this.itemIds = this.itemIds.filter(function (x: any) {
            return x.indexOf("todo") === -1;
        });
        this.itemIds = await S25BulkEditUtil.getMulitTaskIds(this.itemIds);
        S25LoadingApi.destroy(this.elementRef.nativeElement);
        this.init = true;
        this.cd.detectChanges();

        if (this.todoItemIds.length > 0) {
            alert("Cannot complete/ignore To Do tasks via bulk edit. Please act on selected To Do tasks individually.");
        }
    }

    /*
    ngOnInit() {       
        this.mappingModelBean = S25BulkMap[this.itemTypeId][this.itemType]; 
        this.itemIds = this.itemIds.map((x : any)  => x.replace('task_multi_',""));  
        this.itemIds = this.itemIds.map((x : any)  => x.replace('task',"")); 
        this.itemIds = this.itemIds.filter(function (x: any ) { return x.indexOf('todo') === -1; });
        this.init = true;
        this.cd.detectChanges();    
     }
     */

    onToggleChange(event: any) {
        //console.log({event: event, action : this.action});
        this.action = event;

        this.cd.detectChanges();
    }

    get updateFn() {
        return this.update.bind(this);
    }

    update() {
        let payload: any = {};
        payload = {
            taskIdList: this.itemIds,
            action: this.action ? "deny" : "approve",
        };
        //console.log({payload: payload})
        return this.mappingModelBean.service(payload);
    } //END update
}
